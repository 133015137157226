<template>
  <b-row>
    <b-col md="6">
      <b-card
        v-if="user.pm_type"
        title="Payment Information"
      >
        <b-row>
          <b-col>
            <b-form-group
              label-cols-sm="4"
              label-cols-lg="4"
              content-cols-sm
              content-cols-lg="8"
              label="Current payment method"
            >
              <b-input-group
                :prepend="user.pm_type"
              >
                <b-form-input
                  :value="`**** **** **** ${user.pm_last_four}`"
                  disabled
                />
              </b-input-group>
              <div />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        v-if="!user.pm_type"
        title="Getting started"
      >
        <b-row>
          <b-col>
            <p>
              To get started, enter your credit card information below.
            </p>
            <p>
              You will NOT be charged until the end of your 30-day trial, and we’ll send you an email to let you know
              when you’re close so that you can cancel if you’re not 100% satisfied.
              <!--               <a-->
              <!--                href="https://visitplanner.church/product"-->
              <!--                target="_blank"-->
              <!--              >visitplanner.church/product</a>-->
            </p>
          </b-col>
        </b-row>
      </b-card>

      <b-card title="Payment Information">
        <b-form>
          <b-row>
            <b-col
              sm="12"
              class="mb-1"
            >
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="8"
                label="Card"
              >
                <div
                  id="card-element"
                  class="form-control"
                >
                  <!-- Elements will create input elements here -->
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              class="mb-2"
            >
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="8"
                label="Name on Card"
              >
                <b-form-input v-model="cardHolderName" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right">
              <b-button
                variant="primary"
                type="button"
                :disabled="isSubmitting"
                @click="setPaymentMethod"
              >
                {{ addCardButtonLabel }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
  </b-row>

</template>
<script>
import {
  BButton,
  BCard,
  BCol,
  BRow,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BAlert,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import AccountService from '@/services/AccountService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BAlert,
  },
  directives: {
    Ripple,
  },
  data() {
    const user = store.getters['auth/authUser']
    const data = {
      isSubmitting: true,
      stripe: null,
      elements: null,
      cardElement: null,
      cardHolderName: '',
      intentToken: null,
      addCardButtonLabel: '',
      user,
    }

    if (!user.pm_type) {
      data.addCardButtonLabel = 'Start Your 30-Day Trial'
    } else {
      data.addCardButtonLabel = 'Add Card'
    }
    return data
  },
  created() {
    if (document.querySelectorAll('#stripe-js').length === 0) {
      const stripeJs = document.createElement('script')
      stripeJs.setAttribute('id', 'stripe-js')
      stripeJs.setAttribute('src', 'https://js.stripe.com/v3/')
      document.querySelector('#app')
        .appendChild(stripeJs)

      stripeJs.addEventListener('load', () => {
        this.initializeStripe()
      })
    } else {
      this.initializeStripe()
    }
  },
  methods: {
    initializeStripe() {
      this.cardHolderName = this.user.name
      const style = {
        base: {
          iconColor: '#6e6b7b',
          color: '#6e6b7b',
          fontWeight: 400,
          fontFamily:
              '"Montserrat", Helvetica, Arial, serif',
          fontSmoothing: 'antialiased',
          fontSize: '1rem',
          '::placeholder': {
            color: '#aab7c4',
          },
          ':-webkit-autofill': {
            color: '#666ee8',
          },
          padding: '25px',
        },
      }

      AccountService.getSetupIntent()
        .then(response => {
          this.intentToken = response.data
          this.stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)
          this.elements = this.stripe.elements()
          this.cardElement = this.elements.create('card', {
            style,
            hidePostalCode: true,
          })
          this.cardElement.mount('#card-element')
          this.isSubmitting = false
        })
    },
    async setPaymentMethod() {
      this.isSubmitting = true
      try {
        const {
          setupIntent,
          error,
        } = await this.stripe.confirmCardSetup(
          this.intentToken.client_secret, {
            payment_method: {
              card: this.cardElement,
              billing_details: { name: this.cardHolderName },
            },
          },
        )

        if (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Payment Error',
              icon: 'BellIcon',
              text: error.message,
              variant: 'danger',
            },
          })
        } else {
          const response = await AccountService.setPaymentMethod(setupIntent.payment_method)
          await store.dispatch('auth/setAuthUser', response.data.data)
          this.user = store.getters['auth/authUser']

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: 'Your card has been added',
              variant: 'success',
            },
          })

          if (this.user.is_subscription_selection_required) {
            await this.$router.push({ name: 'select-plan' })
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.isSubmitting = false
      }
    },
  },
}
</script>

<style scoped>
#card-element {
  padding-top: 0.6rem;
}

[dir] .alert-primary .alert-heading {
  box-shadow: none;
  background: #FFF;
  border-bottom: 1px solid #eeedfd;
  font-weight: 500;
  font-size: 0.85rem;
}

[dir] .alert-primary {
  background: #FFF;
  border: 1px solid #eeedfd;
  color: #83808e;
}

[dir] .alert-primary {
  background: rgb(115 103 240 / 4%) !important;
}

[dir] .alert-primary button {
  font-weight: 400;
  border: none !important;
  text-decoration: underline;
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

[dir] .alert-primary button:hover {
  background: transparent;
}

[dir] .alert .alert-body {
  color: #83808e;
}
</style>
